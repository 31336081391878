.App {
  text-align: center;
  margin-bottom: 50px;

  @media (max-width: 500px) {
    margin-bottom: 20px;
  }
}

.header {
  padding: 2%;
  font-size: 80px;
  background-color: antiquewhite;

  @media (max-width: 500px) {
    font-size: 40px;
  }
}

.subtitle {
  font-size: 40px;

  @media (max-width: 500px) {
    font-size: 30px;
  }
}

.subtitle-email {
  font-size: 30px;
  @media (max-width: 500px) {
    font-size: 20px;
  }
}

.intro {
  font-size: 18px;
  font-style: italic;
  margin-left: 15%;
  margin-right: 15%;
  padding: 2%;

  @media (max-width: 900px) {
    margin-left: 8%;
    margin-right: 8%;
  }

  @media (max-width: 500px) {
    font-size: 14px;
    margin-left: 2%;
    margin-right: 2%;
  }
}

.demos {
  padding: 1%;
}

.demo-title {
  height: 50px;
  margin-top: 10px;
  padding: 2%;

  @media(max-width: 500px) {
    font-size: 14px;
  }}

.row{
  display: flex;
  justify-content: space-around;
  flex-direction: row;

  @media(max-width: 800px) {
    align-items: center;
    flex-direction: column;
  }
}

.col {
  width: 45%;
  margin: 1%;

  @media(max-width: 1000px) {
    width: 95%;
  }
}

.recent {
  margin: 2%;
}

.section-title {
  font-size: 24px;

  @media (max-width: 500px) {
    font-size: 16px;
  }
}

.recent-list {
  font-size: 18px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 900px) {
    font-size: 14px;
  }
}

.recent-book {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 2%;
  align-items:center;
}

.book-info {
  height: 80px;

  @media (max-width: 900px) {
    height: 60px;
  }
}

.cover-art {
  width: 180px;
  height: 180px;
}